import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

import {
  BREAKPOINT_POST_CONTENT_AREA_MIN,
  BREAKPOINT_POST_CONTENT_AREA_SMALL,
} from '../../../post-page/constants/post-page';
import { getIsDesktop } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';
import ResponsiveContext from './responsive-context';

const CLASS_PREDICATES = {
  xs: (w) => w < 660,
  s: (w) => w >= 660 && w < 686,
  sm: (w) => w >= 686 && w < 980,
  md: (w) => w >= 980 && w < 1280,
  lg: (w) => w >= 1280 && w < 1920,
  xl: (w) => w >= 1920,
  'lt-s': (w) => w < 660,
  'lt-sm': (w) => w < 686,
  'lt-md': (w) => w < 980,
  'lt-lg': (w) => w < 1280,
  'lt-xl': (w) => w < 1920,
  'gt-xs': (w) => w >= 660,
  'gt-s': (w) => w >= 686,
  'gt-sm': (w) => w >= 980,
  'gt-md': (w) => w >= 1280,
  'gt-lg': (w) => w >= 1920,
  'gt-740': (w) => w >= 740,
  'gt-830': (w) => w >= 830,
  'gt-886': (w) => w >= 886,
  'gt-940': (w) => w >= 940,
  'gt-1000': (w) => w >= 1000,
  'gt-1080': (w) => w >= 1080,
  'gt-1200': (w) => w >= 1200,
  'w686-745': (w) => w >= 686 && w <= 745,
  'w686-980': (w) => w >= 686 && w <= 980,
  'lte-w980': (w) => w <= 980,
  'lte-post_content_area_min': (w) => w <= BREAKPOINT_POST_CONTENT_AREA_MIN,
  'lte-post_content_area_small': (w) => w <= BREAKPOINT_POST_CONTENT_AREA_SMALL,
  'lte-banner-w1564': (w) => w <= 1564,
  'lte-banner-w1425': (w) => w <= 1425,
  'lte-banner-w1181': (w) => w <= 1181,
  'lte-banner-w980': (w) => w <= 980,
  'lte-category-header-w1364 ': (w) => w <= 1364,
};

const CLASS_LIST = Object.keys(CLASS_PREDICATES);

const getClassListForWidth = (w, isDesktop) => {
  if (typeof w !== 'number') {
    return isDesktop ? 'use-media-queries' : undefined;
  }
  const classList = CLASS_LIST.reduce((result, className) => {
    if (CLASS_PREDICATES[className](w)) {
      result.push(className);
    }
    return result;
  }, []);
  return classNames(classList);
};

class ResponsiveListener extends Component {
  nodeRef = React.createRef();
  state = {
    width: this.props.width,
    classList: getClassListForWidth(this.props.width, this.props.isDesktop),
  };

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries) => {
      const width = Math.floor(entries[0].contentRect.width);
      if (this.state.width !== width) {
        const classList = getClassListForWidth(width, this.props.isDesktop);
        this.setState({ width, classList });
      }
    });
    this.resizeObserver.observe(this.nodeRef.current);
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    const { width, classList } = this.state;
    const { className } = this.props;
    return (
      <div
        ref={this.nodeRef}
        style={{
          '--root-width': width ? `${width}px` : '100vw',
        }}
        className={classNames(classList, className)}
        data-hook="responsive-listener"
      >
        <ResponsiveContext.Provider value={{ rootWidth: width }}>
          {this.props.children}
        </ResponsiveContext.Provider>
      </div>
    );
  }
}

ResponsiveListener.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
  isDesktop: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  width: host.dimensions.width,
  isDesktop: getIsDesktop(state),
});

export default connect(mapRuntimeToProps)(ResponsiveListener);
