import { EXPERIMENT_OOI_IN_EDITOR, EXPERIMENT_PROD_OOI_EDITOR, } from '@wix/communities-blog-experiments';
import isEnabled from '../../services/is-experiment-enabled';
export var getExperiments = function (state) { var _a; return (_a = state.experiments) !== null && _a !== void 0 ? _a : {}; };
export var isExperimentEnabled = function (state, name, value) {
    // TODO @zanas-wix Remove this workaround once issue is fixed
    // https://wix.slack.com/archives/C01R91XRP99/p1684131575230579?thread_ts=1655976339.260409&cid=C01R91XRP99
    if (Object.keys(getExperiments(state)).length === 0) {
        if (name === EXPERIMENT_PROD_OOI_EDITOR) {
            return false;
        }
        else if (name === EXPERIMENT_OOI_IN_EDITOR) {
            return true;
        }
    }
    return isEnabled(getExperiments(state), name, value);
};
